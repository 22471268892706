import React from 'react';
import { MDXProvider } from '@mdx-js/react';

import { Link } from 'gatsby';

const shortcodes = { Link };

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;
  let header;

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    );
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    );
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>
        <MDXProvider components={shortcodes}>{children}</MDXProvider>
      </main>
    </div>
  );
};

export default Layout;
